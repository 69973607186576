<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <div class="header-text">
      <span>
        <p class="header-text-big">
          深证成指、中小100、创业板指、深证100、深证300
        </p>
        <p class="header-text-small">指数统计</p>
      </span>
    </div>
    <div class="header">
      <div class="lottery-num">
        <p>
          计算结果 {{ first.win1 }},{{ first.win2 }},{{ first.win3 }},{{
            first.win4
          }},{{ first.win5 }}
        </p>
      </div>
      <div class="header-content">
        <el-row>
          <el-col :span="4" class="cont-five">
            <div class="close-title">收盘价格</div>
            <div class="close-time">{{ first.date }}</div>
          </el-col>
          <el-col :span="4" class="cont-price"> {{ first.price1 }} </el-col>
          <el-col :span="4" class="cont-price"> {{ first.price2 }} </el-col>
          <el-col :span="4" class="cont-price"> {{ first.price3 }} </el-col>
          <el-col :span="4" class="cont-price"> {{ first.price4 }} </el-col>
          <el-col :span="4" class="cont-price"> {{ first.price5 }} </el-col>
        </el-row>

      </div>
      <p class="header-source">所有数据均来自 东方财富</p>
    </div>
    <div class="main">
      <div id="app" class="main-chart">
        <v-chart class="my-chart" :option="option" />
      </div>
      <div class="main-table">
        <el-table :data="issues" style="width: 100%">
          <el-table-column prop="issue" label="期数" width="180">
          </el-table-column>
          <el-table-column prop="date" label="时间" width="180">
          </el-table-column>
          <el-table-column prop="price1" label="深证成指"> </el-table-column>
          <el-table-column prop="price2" label="中小100"> </el-table-column>
          <el-table-column prop="price3" label="创业板指"> </el-table-column>
          <el-table-column prop="price4" label="深证100"> </el-table-column>
          <el-table-column prop="price5" label="深证300"> </el-table-column>
          <el-table-column prop="win_num" label="开奖号码">
            <template slot-scope="scope">
              <span>
                {{ scope.row.win1 }},{{ scope.row.win2 }},{{
                  scope.row.win3
                }},{{ scope.row.win4 }},{{ scope.row.win5 }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footer">
      <br />
      <p>Copyright © 2015- 2021 off0 All Rights Reserved.</p>
      <p>
        <a href="#" target="_blank" title="站长统计">站长统计</a> |
        <a href="https://quote.eastmoney.com/" class="">东方财富</a>
      </p>
      <p></p>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import axios from "axios";
import table from "element-ui";
import * as echarts from "echarts";
// import ECharts from "vue-echarts/components/ECharts";

import ECharts from "vue-echarts";
import { use } from "echarts/core";

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import { LinesChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";

use([CanvasRenderer, LinesChart, GridComponent, TooltipComponent]);

Vue.use(table);

export default {
  name: "Home",
  components: {
    "v-chart": ECharts,
  },
  // provide: {
  //   [THEME_KEY]: "light"
  // },
  data() {
    return {
      issues: [],
      first: {
        win1: "*",
        win2: "*",
        win3: "*",
        win4: "*",
        win5: "*",
      },
      timer: null,
      option: {
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        title: {
          left: "center",
          text: "深证成指、中小100、创业板指、深证100、深证300 走势图",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2020-01-01"],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        series: [
          {
            name: "深证成指 收盘价",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "rgb(255, 70, 131)",
            },
            data: [100],
          },
          {
            name: "中小100 收盘价",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "rgb(152, 251, 152)",
            },
            data: [100],
          },
          {
            name: "创业板指 收盘价",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "rgb(216, 191, 216)",
            },
            data: [100],
          },
          {
            name: "深证100 收盘价",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#FFD700",
            },
            data: [100],
          },
          {
            name: "深证300 收盘价",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#87CEEB",
            },
            data: [100],
          },
        ],
      },
    };
  },
  created() {
    this.getIssue();
    // this.getEchats();
    this.timer = setInterval((res) => {
      this.getIssue();
    }, 13000);
  },
  methods: {
    getIssue() {
      axios
        .get(
          "https://grab.cssunions.com/issue-stock?page=1&limit=480&mark=SZ01M05"
        )
        .then((res) => {
          this.issues = res.data.data.lists.slice(0, 48);
          this.first = res.data.data.lists[0];

          var price_date = [];
          var price1 = [];
          var price2 = [];
          var price3 = [];
          var price4 = [];
          var price5 = [];
          var issues = res.data.data.lists;
          // var issues = issues.reverse();
          var num = 0;
          issues.forEach((v) => {
            // console.log(v)
            price_date.push(v.date);
            price1.push(v.price1);
            price2.push(v.price2);
            price3.push(v.price3);
            price4.push(v.price4);
            price5.push(v.price5);
          });

          this.option.series[0].data = price1.reverse();
          this.option.series[1].data = price2.reverse();
          this.option.series[2].data = price3.reverse();
          this.option.series[3].data = price4.reverse();
          this.option.series[4].data = price5.reverse();
          this.option.xAxis.data = price_date.reverse();
        });
    },
    getEchats() {
      axios
        .get(
          "https://grab.cssunions.com/issue-stock?page=1&limit=48&mark=SZ01M05"
        )
        .then((res) => {
          var issues = res.data.data.lists;
          var issues = issues.reverse();
          issues.forEach((v) => {
            // console.log(v)
            this.price_date.push(v.date);
            this.price1.push(v.price1);
            this.price2.push(v.price2);
            this.price3.push(v.price3);
            this.price4.push(v.price4);
            this.price5.push(v.price5);
            this.option.series[0].data = this.price1;
            this.option.series[1].data = this.price2;
            this.option.series[2].data = this.price3;
            this.option.series[3].data = this.price4;
            this.option.series[4].data = this.price5;
            this.option.xAxis.data = this.price_date;
          });
        });
    },
  },
};
</script>

<style scoped>
.in-num {
  font-size: 38px;
  padding-top: 23px;
}
.main-chart {
  padding-top: 30px;
  margin: 0 auto;
  width: 100%;
}
.my-chart {
  width: 100%;
  height: 400px;
}
</style>
